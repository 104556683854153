@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Damion&display=swap');
@import url('https://fonts.googleapis.com/css?family=Alatsi&display=swap');
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    /* touch-action: none; */
  }

  .top-header {
    /* background-color: #faa432; */
    background-color: #0D8FF2;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0;
  }

  .logo-container img {
    width: 55px;
    height: 55px;
  }

  .logo-container-full img {
    width: 200px;
    height: 55px;
  }

  .bottom-navigation {
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    box-shadow: 0px -19px 20px -20px rgba(0,0,0,0.2);
  }

  .bottom-navigation ul {
    list-style-type: none;
    margin: 10px 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }

  .bottom-navigation li {
    padding: 10px;
    margin-top: 10px;
    position: relative;
  }

  .bottom-navigation li a {
    /* display: inline-block; */
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-top: -12px;
  }

  .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #FF675C;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 1;
}

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #0D8FF2 !important;
    border-bottom: 3px solid #0D8FF3 !important;
    background-color: #fff !important;
    border-radius: 0 !important;
}

  /* .icon-home {
    background-image: url('../icons/home.svg');
  }

  .icon-search {
    background-image: url('../icons/search.svg');
  }

  .icon-pets {
    background-image: url('../icons/pets.svg');
  }

  .icon-profile {
    background-image: url('../icons/profile.svg');
  } */

  /* Change color on hover */
  .bottom-navigation li a:hover {
    filter: brightness(1.2); /* Increase brightness on hover */
  }
  /* Change color when active */
  .bottom-navigation li a:active {
    filter: brightness(0.8); /* Decrease brightness when active */
  }

  .bottom-navigation li.special {
    padding: 10px;
    z-index: 999;
    position: relative;
    top: -20px;
  }

  .bottom-navigation img {
    position: relative;
    top: -10px;
  }

  .bottom-navigation a {
    text-decoration: none;
    color: #9db2ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
  }

  .bottom-navigation a:hover,
  .bottom-navigation a.active {
    /* color: #faa432; */
    color: #0D8FF2;
  }

  .bottom-navigation img a:hover,
  .bottom-navigation img a:active {
    /* color: #faa432; */
    color: #0D8FF2;
  }

  .status-lost {
    background-color: #FF675C;
  }

  .status-found {
    background-color: #FFB65C;
    color: #000;
  }

  .bottom-navigation .plus {
    width: 60px;
    height: 60px;
    background-color: #faa432;
    border-radius: 50px;
    border: 5px solid #FFBD65;
  }

  .bottom-navigation .plus img {
    margin-bottom: 5px;
    position: relative;
    top: 8px;
  }

  .bottom-navigation .plus:hover {
    /* background-color: #faa432;
    border-color: #FFBD65; */
    background-color: #0D8FF2;
    border-color: 5px solid #47BBF5;
  }

  .menuToggle {
    position: relative;
    width: 80px;
    height: 80px;
    top: -30px;
    /* background: #faa432;
    border: 5px solid #FFBD65; */
    background: #0D8FF2;
    border: 5px solid #47BBF5;
    border-radius: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
  }
  .menuToggle::before {
    content: '+';
    position: absolute;
    font-size: 3em;
    font-weight: 200;
    color: #ffffff;
    transition: 1.5s;
    top: -5px;
    left: 21px;
  }
  .menuToggle.active::before {
    transform: rotate(225deg);
    top: 2px;
    left: 18px;
  }
  .menu {
    position: absolute;
    width: 30px;
    height: 30px;
    /* background: #faa432; */
    background: #0D8FF2;
    border-radius: 70px;
    z-index: -1;
    transition: transform 0.5s, width 0.5s, height 0.5s;
    transition-delay: 1s, 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .menu img {
      position: relative;
      top: -3px;
  }

  .menuToggle.active~.menu {
    width: 240px;
    height: 70px;
    padding-top: 4px;
    z-index: 1;
    transform: translateY(-112px);
    transition-delay: 0s, 0.5s, 0.5s;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    position: absolute;
    /* top: -20px; */
    /* left: -76px; */
  }
  .menu::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    /* background: #faa432; */
    background: #0D8FF2;
    left: calc(50% - 8px);
    bottom: 4px;
    transform: rotate(45deg);
    border-radius: 2px;
    transition: 0.5s;
  }
  .menuToggle.active~.menu::before {
    transition-delay: 0.5s;
    bottom: -2px;
  }
  .menu ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 70px;
    gap: 15px;
    padding: 0;
    top: -10px;
  }
  .menu ul li {
    list-style: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: 0.25s;
    transition-delay: calc(0s + var(--i));
    position: relative;
    top: 5px;
  }
  .menuToggle.active~.menu ul li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
  }
  .menu ul li a {
    /* display: block; */
    font-size: 0.8em;
    text-decoration: none;
    color: #ffffff;
    width: auto;
    margin: -20px 0 0 0;
  }
  .menu ul li a:hover {
    color: #ffffff !important;
    cursor: pointer;
  }

  .menuFix {
    position:relative;
    top: -100px;
  }

  .lpcontainer {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding-bottom: 110px;
    top: 70px;
    position: relative;
}

.header-right {
  position: absolute;
  right: 40px;
  top: 20px;
}

.carousel-controls {
  display: flex;
  /* justify-content: center; */
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
}

.control-button {
  background-color: #ff5a5f;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-button:hover {
  background-color: #ff8080;
}

.control-button:focus {
  outline: none;
}

.carousel-controls {
  display: flex;
  justify-content: space-around;
  padding: 0px;
}

.control-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
}

.control-item .flaticon {
  font-size: 24px;
  margin-bottom: 8px;
}

.control-item .control-text {
  font-size: 14px;
}

@media (max-width: 600px) {
  .control-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 32px;
  line-height: 1.25;
  font-style: normal;
}

.formContainer {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

  .lpcontainer h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #5a5a5a;
    margin: 20px 0;
}

.lpcontainer p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #5a5a5a;
}

.btn-primary {
  background-color: #0D8FF2 !important;
  font-family: "Montserrat", sans-serif;
  color: #fff !important;
  border: 0 !important;
  float: right;
  margin: 0 0 20px 0;
}

.btn-lost {
  background-color: #FF675C !important;
  color: #fff !important;
}

.btn {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

 .icon {
  margin-right: 12px;
  top:5px;
  position: relative;
}

.icon-pet {
  margin-right: 0px;
  top:5px;
  position: relative;
}

.links {
  color: #FFB65C;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

.button-text {
  position: relative;
  top: -5px;
}

.lost-pet-button:hover {
  background-color: #FE917B;
}

.swipe {
  width: 100%;
}

.swipe img {
    object-fit: cover;
}

.photo {
    background-color: transparent;
    overflow: hidden;
}

.photo img {
    /* width: 100%; */
    height: auto;
    /* border-radius: 10px 10px 0 0; */
}

.buttons {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 75%;
    margin: 0 auto;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.dislike {
    background-color: #e74c3c;
    color: white;
}

.like {
    background-color: #2ecc71;
    color: white;
}

.dashboard-card {
    position: relative;
    /* width: 360px;
    height: 360px; */
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;
  }

  .dashboard-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .status-pill {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #ff0000;
      color: #fff;
      padding: 5px 10px;
      border-radius: 10px;

  }

  .card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  .card-overlay p {
    margin: 0;
    font-size: 16px;
    padding: 10px;
  }

  .card-overlay button {
    background-color: #faa432;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    margin: 10px 8px;
    width: 45%;
  }

  .slider {
    width: 100%;
  }

  .card-overlay button:hover {
    background-color: #FFBD65;
  }

  .more-info {
    background-color: #f7f7f7 !important;
  border: #e0e0e0 !important;
    color: #323232 !important;
  }

  .more-info:hover {
    background-color: #cccccc;
    border: #aaaaaa;
    color: #323232;
  }

  /* @font-face {
    font-family: 'locatepets';
    src:  url('../icons/fonts/locatepets.eot?mrtsyx');
    src:  url('../icons/fonts/locatepets.eot?mrtsyx#iefix') format('embedded-opentype'),
      url('../icons/fonts/locatepets.ttf?mrtsyx') format('truetype'),
      url('../icons/fonts/locatepets.woff?mrtsyx') format('woff'),
      url('../icons/fonts/locatepets.svg?mrtsyx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  } */
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'locatepets' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-home-nav:before {
    content: "\e900";
    color: #9db2ce;
  }
  .icon-pets-nav:before {
    content: "\e901";
    color: #9db2ce;
  }
  .icon-profile-nav:before {
    content: "\e902";
    color: #9db2ce;
  }
  .icon-search-nav:before {
    content: "\e903";
    color: #9db2ce;
  }
  .icon-home-nav:hover:before {
    content: "\e900";
    color: #faa432;
  }
  .icon-pets-nav:hover:before {
    content: "\e901";
    color: #faa432;
  }
  .icon-profile-nav:hover:before {
    content: "\e902";
    color: #faa432;
  }
  .icon-search-nav:hover:before {
    content: "\e903";
    color: #faa432;
  }
  .pets-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .header h1 {
    margin: 0;
    font-size: 24px;
  }

  .header .add-icon {
    cursor: pointer;
    font-size: 24px;
  }

  .category {
    margin-bottom: 20px;
  }

  .category h2 {
    margin: 10px 0;
    font-size: 20px;
  }

  .pet-card {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }

  .pet-card img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .pet-info {
    flex-grow: 1;
    position: relative;
    padding: 0px;
    margin-bottom: 20px;
  }

  .pet-info h3 {
    margin: 0;
    font-size: 16px;
  }

  .pet-info p {
    margin: 5px 0;
    font-size: 14px;
  }

  .lost-pets {
    border-top: 0px solid #FF6347;
  }

  .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: block;
  }

  .profile-form {
    margin-bottom: 20px;
  }

  .profile-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .profile-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .sign-out {
    text-align: center;
  }

  .sign-out a {
    color: #FF6347;
    text-decoration: none;
  }

  .icon-active {
    color: #0D8FF2;
  }

  body {
    /* Ensure body takes the full space of the screen */
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }

  .safe-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: env(safe-area-inset-top);
    background-color: #0D8FF2; /* Match the status bar color */
    z-index: 9999;
    pointer-events: none; /* Prevent interfering with clicks */
  }
